export var Backend = {
    Decap: 'decap',
    Drupal: 'drupal'
};
export var BlockTeaserListLayout = {
    Carousel: 'CAROUSEL',
    Grid: 'GRID'
};
export var CtaIconPosition = {
    After: 'AFTER',
    Before: 'BEFORE'
};
export var CtaIconType = {
    Arrow: 'ARROW',
    None: 'NONE'
};
export var ImagePosition = {
    Left: 'left',
    Right: 'right'
};
export var InfoGridIcon = {
    Email: 'EMAIL',
    LifeRing: 'LIFE_RING',
    None: 'NONE',
    Phone: 'PHONE'
};
export var Locale = {
    De: 'de',
    En: 'en'
};
export var CreateSubmissionMutation = "CreateSubmissionMutation:b585180e5579cb960b45197ad15ac71e5dc70d92bc935df5e2c92574edcd8d37";
export var ContentHubQuery = "ContentHubQuery:b9e2b7105b9ca7f372a9f3979165cbf818ea7e249504a0856c4f00936b559366";
export var ContentHubTermsQuery = "ContentHubTermsQuery:4c8c7f5b6bc53d9638289cc9d4e60e55ba51534e8ec81a6c8903604e7a27416e";
export var FrameQuery = "FrameQuery:0947676cc7f3b3a8580100f8e3321d4796c6e4fc744ed6becc287c6d6c26f763";
export var HomePageQuery = "HomePageQuery:e6422098999003bd2b0f6e68a77942f325095e2130fe4c84e424cf80bb475df2";
export var ListPagesQuery = "ListPagesQuery:1f7e06f1abff052f072012075876848f4df447641e84c1cb9ab5809ae923108a";
export var NotFoundPageQuery = "NotFoundPageQuery:3cca614e16a7fe75c4ab4a5db3b04293fc361c9cbda890a54c64707b3b9019fd";
export var PreviewDecapPageQuery = "PreviewDecapPageQuery:e2c0477269d0cf26a568585ea29a0919dc38bbb2988ee74d321456f081183944";
export var PreviewDrupalPageQuery = "PreviewDrupalPageQuery:fc39a2cc3be7b2f34fc114ea9fc17f32f3eed72f8ee476d38b68d0637ee6483c";
export var TeaserListQuery = "TeaserListQuery:5c6804cfbe9fd4d40d09cb075d94af9d5bf4625668d6aeff31e6dfc249ed4546";
export var ViewPageQuery = "ViewPageQuery:c4743a36b70611c0926fc00ac1fd3168603f81a6e70ee88791272667947a5abb";
